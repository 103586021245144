@charset 'UTF-8';

@import '1-settings/_base-imports';
@import '2-tools/_base-imports';
@import '3-generic/_base-imports';
@import 'foundation/components/visibility';

$header-height: 75;
$error-font: 'Roboto Condensed', $body-font;

html,
body
{
	background-color: $smoke;
	font-family: $body-font;
	margin: 0;
	padding: 0;
}

a
{
	color: $primary-color;
	text-decoration: none;

	&:hover
	{
		text-decoration: underline;
	}
}

.error-root
{
	overflow: hidden;

	&__container
	{
		box-shadow: rem-calc(0 -5 10) $base, rem-calc(5 0 10) $base, rem-calc(0 5 10) $base, rem-calc(-5 0 10) $base;
		max-width: rem-calc(990);
		min-width: rem-calc(300);
	}
}

.error-header
{
	background-color: $blue;
	background-image: linear-gradient(lighten($blue, 5%), $blue);

	&--fatal
	{
		.error-header__logo
		{
			background-position: 0 center;
			float: none;
			margin: 0 auto;
			text-indent: $negative-indent;
			width: rem-calc(200);
		}
	}

	&__logo
	{
		background: url('../images/logos/parkers.200x40.png') no-repeat rem-calc($header-height + $rem-base) center;
		color: $white;
		float: left;
		height: rem-calc($header-height);
		width: rem-calc(200 + $header-height + $rem-base);

		&:hover
		{
			.error-header__logo__icon
			{
				background-color: $cyan;
				color: $white;
			}
		}

		&__icon
		{
			display: inline-block;
			font-size: 2.5rem;
			height: rem-calc($header-height);
			line-height: rem-calc($header-height);
			text-align: center;
			transition: background-color 200ms ease 0s;
			width: rem-calc($header-height);
		}
	}

	&__search
	{
		font-family: $error-font;
		margin: 1rem;

		&__input
		{
			background: $white url('../images/icons/24x24/magnifying-glass-blue.png') no-repeat 0.25rem center;
			border: 0;
			font-size: 1rem;
			height: rem-calc($header-height - (2 * $rem-base));
			margin: 0;
			padding: 0.25rem 0.25rem 0.25rem 2rem;
			width: 78%;
		}

		&__button
		{
			background-color: $cyan;
			border: 0;
			box-shadow: rem-calc(2 3 0 0) $oil;
			color: $white;
			font-size: 1.1rem;
			line-height: rem-calc($header-height - (2 * $rem-base));
			margin: 0;
			padding: 0 1rem;
			text-transform: uppercase;
			transition: all 200ms;
			width: 20%;

			&:hover
			{
				background-color: $sky-blue;
			}
		}
	}
}

.error-body
{
	background-color: $white;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: contain;

	&.bg-rusty-car
	{
		background-image: url('../images/backgrounds/rusty-car_660x371.jpg');
		padding-bottom: 56%;
	}

	&.bg-shocked-man
	{
		background-image: url('../images/backgrounds/driving-shock_660x440.jpg');
		padding-bottom: 67%;
	}

	&.bg-teapot
	{
		background-image: url('../images/backgrounds/teapot_660x440.jpg');
		padding-bottom: 67%;
	}

	&.bg-tow-truck
	{
		background-image: url('../images/backgrounds/tow-truck_660x440.jpg');
		padding-bottom: 67%;
	}
}

.error-panel
{
	color: $jet;
	font-family: $error-font;
	font-weight: bold;
	padding: 2rem;
	text-align: center;
	text-shadow: rem-calc(2 2 4) $base;

	&__code
	{
		font-size: 2.5rem;
		font-weight: 300;
		line-height: 1;
		text-transform: uppercase;

		&__number
		{
			font-size: 5rem;
		}
	}

	&__text
	{
		font-size: 2rem;
		font-weight: 600;
		line-height: 1;
		margin: 1rem 0 0 0;

		&--large
		{
			font-size: 3rem;
		}
	}
}

.error-description
{
	font-family: $error-font;
	font-size: 1.2rem;
	overflow: hidden;
	padding: 2rem;

	p
	{
		line-height: 1.2;
		margin: 0 0 1rem 0;
	}
}

.error-footer
{
	background-color: $charcoal;
	background-image: linear-gradient($steel, $charcoal);
	color: $white;
	padding: 1rem 1rem 1.5rem 1rem;

	&__bauer-logo
	{
		float: left;
		margin-right: 1rem;
	}

	&__copyright,
	&__company-info
	{
		color: $smoke;
		font-size: 0.8rem;
		line-height: 1.2;
		margin: 0.5rem 0 0;
	}

	&__company-info
	{
		font-size: 0.7rem;
	}
}

@media (min-width: 660px)
{
	.error-header
	{
		&__search
		{
			float: right;
			width: rem-calc(300);
		}
	}

	.error-body
	{
		&.bg-rusty-car
		{
			background-image: url('../images/backgrounds/rusty-car_990x557.jpg');
		}

		&.bg-shocked-man
		{
			background-image: url('../images/backgrounds/driving-shock_990x660.jpg');
		}

		&.bg-teapot
		{
			background-image: url('../images/backgrounds/teapot_990x660.jpg');
		}

		&.bg-tow-truck
		{
			background-image: url('../images/backgrounds/tow-truck_990x660.jpg');
		}
	}

	.error-panel
	{
		float: left;
		width: 35%;
	}
}

@media (min-width: 990px)
{
	.error-root
	{
		&__container
		{
			margin: 3rem auto;
		}
	}
}
